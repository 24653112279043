<template>
  <div class="content">
    <h1>Invoices</h1>

    <div class="flex flex-col gap-4">
      <b-tabs pills v-model="pageIndex">
        <b-tab title="Invoices"></b-tab>
        <b-tab title="Suppliers"></b-tab>
      </b-tabs>

      <invoice-list v-if="pageIndex === 0" />
      <partner-list v-if="pageIndex === 1" />
    </div>
  </div>
</template>

<script>
const InvoiceList = () => import('@/components/invoices/InvoiceList.vue');
const PartnerList = () => import('@/components/invoices/PartnerList.vue');

export default {
  name: 'Invoices',
  components: {
    InvoiceList,
    PartnerList,
  },
  data() {
    return {
      pageIndex: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
